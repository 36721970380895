<template>
  <div>
    <b-loading v-model="loading"></b-loading>

    <div
      v-if="!loading"
      class="is-flex is-flex-wrap-wrap is-align-items-center my-4"
    >
      <h1 class="mr-5">
        <span>
          {{ $tc("accessory", 2) }} &ndash; {{ accessory.name }} &ndash;
        </span>
        {{ $t("edit") }}
      </h1>
    </div>

    <div v-if="!loading" class="card">
      <div class="card-content pt-3">
        <BackButton
          :parent-route="{ name: 'AccessoryView', params: { id } }"
        ></BackButton>

        <AccessoryForm
          :mode="'edit'"
          :accessory="accessory"
          @submit="submit"
        ></AccessoryForm>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import BackButton from "../../components/BackButton";
import AccessoryForm from "./partials/AccessoryForm";

export default {
  name: "AccessoryEdit",
  components: { BackButton, AccessoryForm },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      accessory: null,
    };
  },
  mounted() {
    this.getAccessory(this.id).then((accessory) => {
      this.accessory = accessory;
      this.loading = false;
    });
  },
  methods: {
    ...mapActions(["getAccessory", "updateAccessory"]),
    submit(payload) {
      this.loading = true;

      this.updateAccessory({
        id: this.accessory.id,
        accessory: payload,
      })
        .then((accessory) => {
          this.$buefy.toast.open({
            message: this.$t("accessory_updated"),
            type: "is-primary",
          });
          this.$router.push({
            name: "AccessoryView",
            params: { id: accessory.id },
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
